// @import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
/*********************************************/
/*          CUSTOMIZED DEFAULTS         */
/*********************************************/

$body-bg: #f8f8f8;
;
$staff-color: #e5e5e5;
$section-color: #d5d5d5;
$spacer-color: transparent;
$border-color: #cdd7e1;
$body-color: #1d1d1d;
$highlight-color: #cce1ff;
// $container-font-size: 1rem;
$container-font-size: clamp(8px, calc(115vw / 90), 16px);
$container-letter-spacing: clamp(-1px, calc(-1 * (2ch / 18.5)), -0.55px);
// $container-letter-spacing: calc(-1px);
// $container-letter-spacing: calc((-#{$container-font-size} ) / 16);
// $container-letter-spacing: clamp( -1px, calc((100vw * .8) / 80 * -1), -0.5px);
$font-family-sans-serif: "Poppins", sans-serif;
$font-family-monospace: "Courier New", Courier, monospace;
$staff-container-border-radius: 4px;
$staff-row-padding: 0.25rem 0rem 0.25rem 0.5rem;
$top-toolbar-height: 4.5rem;
$bottom-toolbar-height: 2rem;
$primary: #f4a83a;
$staff-container-grid-template-columns: 12ch 4px auto;
$grid-column-name: 1 / 1;
$grid-column-row: 3 / 1;
$staff-container-border: 1px solid $border-color;
$staff-container-min-width: 125ch;


// 142 is what fits on my laptop
$canvas-width: 100%;
// $font-family-monospace: 'Courier New', Courier, monospace;

.toolbar-button {
  border-radius: 2px;
  background: $staff-color;
  border-color: $staff-color;
  color: $body-color;
}

.toolbar-button:hover,
.toolbar-button:active,
.toolbar-button:focus,
.toolbar-button:focus-within,
.toolbar-button:focus-visible,
.toolbar-button:target,
.toolbar-button:checked {
  border-radius: 2px;
  background: $section-color;
}

.top-toolbar-container {
  // padding-top: 8px;
  position: relative;
  top: 0px;
  background-color: $body-bg;
  align-content: baseline;
  display: grid;
  padding-top: 8px;
  grid-template-columns: repeat(12, 1fr);
  height: $top-toolbar-height;
  column-gap: 24px;
  box-shadow: 0 4px 2px -2px gray;
  justify-content: start;
  z-index: 2;
}

.bottom-toolbar-container {
  height: $bottom-toolbar-height;
  background-color: #f8f8f8;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  position: relative;
  bottom: 0;
  display: flex;
  z-index: 2;
}


.staff-annotation {
  grid-column: 1 / 1;
  display: grid;
  height: auto;
  position: relative;
  transition: ease-in 0.5s;
  z-index: -1;

  svg {
    width: 1rem;
    height: auto;
    display: grid;
    align-self: center;
  }

}

.editor-container {
  font-family: $font-family-monospace;
  font-size: $container-font-size;
  letter-spacing: $container-letter-spacing;
  position: relative;
  height: calc(100vh - (#{$top-toolbar-height} + #{$bottom-toolbar-height}));
  overflow-y: auto;
  max-width: fit-content;
  min-width: 100vw;
}

.staff-ruler {
  border-right: 2px dotted rgba(0, 0, 0, 0.25);
  padding-right: 4px;
  position: absolute;
  left: $staff-container-min-width;
  // height: staffHeight,
  min-height: calc(100% - 1rem);
  font-size: inherit;
  top: 5px;
  font-family: $font-family-monospace;
  font-size: $container-font-size;
  letter-spacing: $container-letter-spacing;
}

.canvas-container {
  font-family: $font-family-monospace;
  font-size: $container-font-size;
  letter-spacing: $container-letter-spacing;
  padding: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.staff-container {
  display: grid;
  grid-template-columns: $staff-container-grid-template-columns;
  height: auto;
  min-width: $staff-container-min-width;
  max-width: fit-content;
  font-family: $font-family-monospace;
  font-size: inherit;
  letter-spacing: $container-letter-spacing;
}

.staff-container-section-elements {
  display: grid;
  grid-template-columns: $staff-container-grid-template-columns;
  transition: margin-top ease-in 0.5s;
  margin-top: 0rem;
  margin-top: 0;
  min-width: $staff-container-min-width;
  max-width: fit-content;
  font-family: $font-family-monospace;
  font-size: inherit;
  letter-spacing: $container-letter-spacing;
}

.row-spacer {
  padding: $staff-row-padding;
  background-color: $spacer-color;
  grid-column: $grid-column-row;
}

.row-spacer-empty {
  padding: $staff-row-padding;
  background-color: $spacer-color;
  grid-column: $grid-column-row;
}

.name-spacer {
  padding: $staff-row-padding;
  background-color: $spacer-color;
  grid-column: $grid-column-name;
}

.staff-row {
  border-radius: $staff-container-border-radius;
  grid-column: $grid-column-row;
  background-color: $staff-color;
  border: $staff-container-border;
  padding: $staff-row-padding;
  cursor: pointer;
  white-space: pre;
  align-content: center;
  font-family: $font-family-monospace;
  font-size: inherit;
  letter-spacing: $container-letter-spacing;

  input {
    font-size: inherit;
    letter-spacing: inherit;
    width: 100%;
  }
}

.staff-row-presentation {
  border-radius: $staff-container-border-radius;
  grid-column: $grid-column-row;
  background-color: $staff-color;
  border: $staff-container-border;
  padding: $staff-row-padding;
  white-space: pre;
  align-content: center;
  font-family: $font-family-monospace;
  font-size: inherit;
  letter-spacing: $container-letter-spacing; 
  position: relative;
  
  p {
    margin: 0;
  }
}

.section-row {
  grid-column: $grid-column-row;
  background-color: $section-color;
  border-radius: $staff-container-border-radius;
  border: $staff-container-border;
  padding: $staff-row-padding;
  // cursor: pointer;
  align-content: center;
  white-space: pre;
  overflow: clip;
  font-family: $font-family-monospace;
  font-size: inherit;
  letter-spacing: $container-letter-spacing;

  input {
    font-size: inherit;
    letter-spacing: inherit;
    width: 100%;
  }
}

.section-row-presentation {
  grid-column: $grid-column-row;
  background-color: $section-color;
  border-radius: $staff-container-border-radius;
  border: $staff-container-border;
  padding: $staff-row-padding;
  white-space: pre;
  overflow: clip;
  font-family: $font-family-monospace;
  font-size: inherit;
  letter-spacing: $container-letter-spacing;
  align-content: center;

  input {
    font-size: inherit;
    letter-spacing: inherit;
    width: 100%;
  }
}

.staff-name {
  grid-column: $grid-column-name;
  display: grid;
  overflow: hidden;
  border-radius: $staff-container-border-radius;
  text-align: center;
  align-content: center;
  background-color: $staff-color;
  border: $staff-container-border;
}

.section-name {
  grid-column: $grid-column-name;
  display: grid;
  border-radius: $staff-container-border-radius;
  // text-wrap: balance;
  overflow: hidden;
  text-align: center;
  align-content: center;
  background-color: $section-color;
  border: $staff-container-border;
  cursor: pointer;
  transition: background-color .25s;
}

.section-name-presentation {
  grid-column: $grid-column-name;
  display: flex;
  justify-content: space-between;
  border-radius: $staff-container-border-radius;
  // text-wrap: balance;
  overflow: hidden;
  /* text-align: center; */
  align-content: center;
  background-color: $section-color;
  border: $staff-container-border;
  cursor: pointer;
  transition: background-color .25s;

  p {
    margin: 4px 0px 0 4px;

  }
}

.section-name-presentation:hover {
  background-color: $highlight-color;
}

.section-name:hover {
  background-color: $highlight-color;
}

.page-nav-button {
  font-size: larger;
}

.timeline {
  height: 1rem;
  border: 2px solid black;
  width: inherit;
}

@keyframes slide-left {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.animate-slide-left {
  animation: slide-left 0.5s ease-in-out;
  overflow-x: hidden;
}


@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.1s ease-in-out;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-gradient {
  position: relative;
  display: inline-block;
}

.image-gradient:before {
  border-radius: 8px;
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 49%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(49%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 49%, rgba(255, 255, 255, 1) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 49%, rgba(255, 255, 255, 1) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 49%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 49%, rgba(255, 255, 255, 1) 100%);
}



@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

